import csLegal from './locales/cs/legal.json';
import csHeader from './locales/cs/header.json';
import csSitemap from './locales/cs/sitemap.json';
import csTimeline from './locales/cs/timeline.json';
import csModal from './locales/cs/modal.json';

import skLegal from './locales/sk/legal.json';
import skHeader from './locales/sk/header.json';
import skSitemap from './locales/sk/sitemap.json';
import skTimeline from './locales/sk/timeline.json';
import skModal from './locales/sk/modal.json';

import deLegal from './locales/de_DE/legal.json';
import deHeader from './locales/de_DE/header.json';
import deSitemap from './locales/de_DE/sitemap.json';
import deTimeline from './locales/de_DE/timeline.json';
import deModal from './locales/de_DE/modal.json';

import huLegal from './locales/hu_HU/legal.json';
import huHeader from './locales/hu_HU/header.json';
import huSitemap from './locales/hu_HU/sitemap.json';
import huTimeline from './locales/hu_HU/timeline.json';
import huModal from './locales/hu_HU/modal.json';

import enLegal from './locales/en/legal.json';
import enHeader from './locales/en/header.json';
import enSitemap from './locales/en/sitemap.json';
import enTimeline from './locales/en/timeline.json';
import enModal from './locales/en/modal.json';

export default defineI18nConfig(() => {
    return {
        legacy: false,
        locale: 'cs',
        fallbackLocale: 'cs',
        globalInjection: true,
        messages: {
            cs: {
                legal: csLegal,
                header: csHeader,
                sitemap: csSitemap,
                timeline: csTimeline,
                modal: csModal,
            },
            sk: {
                legal: skLegal,
                header: skHeader,
                sitemap: skSitemap,
                timeline: skTimeline,
                modal: skModal,
            },
            de_DE: {
                legal: deLegal,
                header: deHeader,
                sitemap: deSitemap,
                timeline: deTimeline,
                modal: deModal,
            },
            hu_HU: {
                legal: huLegal,
                header: huHeader,
                sitemap: huSitemap,
                timeline: huTimeline,
                modal: huModal,
            },
            en: {
                legal: enLegal,
                header: enHeader,
                sitemap: enSitemap,
                timeline: enTimeline,
                modal: enModal,
            },
        }
    };
});
